import { Router } from 'vue-router';

const { $sanctumAuth } = useNuxtApp();
const router: Router = useRouter();

export const useLogOut = async (): Promise<void> => {
  await $sanctumAuth.logout((): void => {
    router.push('/login');
  });
};
