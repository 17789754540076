<script setup lang="ts">
import { NotificationData } from '@notifications/types';
import { useNotificationsStore } from '@notifications/stores';
import { useEnquiriesStore } from '@enquiries/stores';
import UhTooltip from '~/components/general/uhTooltip/UhTooltip.vue';
const dayjs = useDayjs();
const router = useRouter();
const enquiriesStore = useEnquiriesStore();
const notificationsStore = useNotificationsStore();

type EnquiryNotificationCardProps = {
  notification: NotificationData;
};

const props = defineProps<EnquiryNotificationCardProps>();

function viewEnquiry() {
  // User clicked on it, so mark it as read
  if (!props.notification.isRead) {
    notificationsStore.markAsRead(props.notification.id);
  }

  // Set the enquiries page filter - id
  enquiriesStore.query.filter.id = props.notification.data.id;

  notificationsStore.isOpen = false;

  router.push({
    name: 'enquiries',
  });
}
</script>

<template>
  <div
    class="relative cursor-pointer border-b px-6 py-4 hover:bg-gray-50"
    @click="viewEnquiry"
  >
    <div class="flex items-center justify-between">
      <h2 class="pr-6 text-sm">
        <span class="font-semibold capitalize">
          {{ props.notification.data.name }}</span
        >
        enquired about
        <span class="font-semibold">{{ props.notification.data.address }}</span>
      </h2>
      <UhTooltip hover placement="left">
        <button
          v-if="!notification.isRead"
          type="button"
          class="relative z-[2] h-2 w-2 rounded-full bg-red-500"
          @click.stop="notificationsStore.markAsRead(notification.id)"
        ></button>

        <template #content>Mark as read</template>
      </UhTooltip>
    </div>
    <p class="mt-1 text-xs text-gray-500">
      {{ dayjs(notification.createdAt).fromNow() }}
    </p>
  </div>
</template>
