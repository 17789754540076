<script setup lang="ts">
import HeaderNavUserMenu from './HeaderNavUserMenu.vue';
import SidebarButton from './SidebarButton.vue';
import ImpersonatingBanner from './ImpersonatingBanner.vue';
import UhButton from '~/components/general/uhButton/UhButton.vue';
import NotificationsPanel from '~/modules/notifications/components/NotificationsPanel.vue';
import { useMainStore } from '~/stores/mainStore';

const mainStore = useMainStore();

const showBackToPropertiesButton = computed<boolean>(
  () => useRoute().meta.layout === 'checkout'
);

interface AppHeaderProps {
  showSideBarButton?: boolean;
}

withDefaults(defineProps<AppHeaderProps>(), {
  showSideBarButton: true,
});

function onReturnToPropertiesClick(): void {
  useRouter().push('/properties');
}
</script>

<template>
  <header class="w-full">
    <ImpersonatingBanner />
    <nav class="left-0 right-0 z-20 w-full bg-blue-500" data-cy="header-nav">
      <div class="flex w-full items-center px-4 py-4 md:px-8">
        <template v-if="showBackToPropertiesButton">
          <UhButton
            color="default"
            type="button"
            class="mr-8 flex max-h-8 items-center border border-white"
            @click="onReturnToPropertiesClick"
          >
            <FontAwesomeIcon
              :icon="['fas', 'arrow-left']"
              style="height: 20px"
              class="w-5"
            />
            <span class="ml-2 hidden xs:block">Return to Properties</span>
          </UhButton>
        </template>

        <template v-else>
          <SidebarButton v-if="showSideBarButton" class="mr-4 md:mr-8" />

          <!-- + Logo -->
          <NuxtLink to="/" class="ml-0 mr-8 items-center md:mr-0 xl:ml-0">
            <img
              class="h-8 fill-gray-900"
              src="~/assets/img/unihomes_logo.svg"
              alt="UniHomes Logo"
              style="filter: brightness(0) invert(1)"
            />
          </NuxtLink>
          <!-- - Logo -->
          <!-- + Beta pill to swallow... -->
          <div class="mr-auto">
            <span
              v-if="mainStore.siteSettings.showAgentPortalBetaToggleSwitch"
              class="mb-0.5 ml-2 mt-auto hidden select-none rounded-full bg-senary-900 px-2 py-0.5 text-xs font-bold text-white md:flex"
            >
              Beta
            </span>
          </div>
          <!-- - Beta pill to swallow... -->
        </template>

        <!-- + Notifications button -->
        <NotificationsPanel />
        <!-- - Notifications button -->

        <!-- + Help button -->
        <a
          href="https://unihomes.helpdocs.io/"
          target="_blank"
          rel="noopener noreferrer"
          class="ml-4 mr-0 hidden rounded-full p-1 text-white hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 xl:block"
        >
          <span class="sr-only">Help</span>
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            ></path>
          </svg>
        </a>
        <!-- - Help button -->
        <HeaderNavUserMenu />
      </div>
    </nav>
  </header>
</template>

<style scoped lang="scss"></style>
